import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";

export const NodejsScreen = () => {
    return (
        <Box sx={{ flex: 1 }}>

            <CodeExplain title={'New node js app'}
                code={`
create node app
inside folder(appName)
npm init
        `} />

            <CodeExplain title={'Node j with express inside App.js'}
                code={`
import express from "express";
import cors from "cors";
import yourRouter from "./app/routs/your_router.js";
import { createServer } from "http";
import MESSAGES from "./app/utils/MESSAGES.js";
import ROUTES from "./app/utils/ROUTES.js"

const app = express();
const server = createServer(app);

app.use(cors());
app.use(express.json());
app.use(express.urlencoded({ extended: true }));

// welcom route
app.all(ROUTES.BASE, (req, res) => {
    SendNoramlResponse(res, MESSAGES.SUCCESS_STATUS, MESSAGES.WELCOME, null);
});

//adding routs into app
app.use(ROUTES.BASE, yourRouter);

//if routs not matched
app.all('*', function (req, res) {
    return res.status(MESSAGES.NO_ROUT_FOUND_STATUS).json({status: MESSAGES.NO_ROUT_FOUND_STATUS, message:MESSAGES.NO_ROUT_FOUND});
})

server.listen(CONSTANTS.PORT, function () {
    console.log("Express server listening on port 3000 " + new Date());

});

export default app;`} />

            <CodeExplain title={'Object instead array from lookup'}
                code={`
{
    $unwind: {
    path: "$userDoc",
    preserveNullAndEmptyArrays: true //optional if want blank object
    }
}
`} />

        </Box>
    )
}

export default NodejsScreen;