import { combineReducers } from 'redux';
import REDUX_CONSTANTS from '../utils/ReduxConstants';

const alertDetails = (state = null, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.SHOW_ALERT:
            return action.data;
        default:
            return state;
    }
}

export default combineReducers({
    alertDetails
});