import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";

export const GithubScreen = () => {
    return (
        <Box sx={{ flex: 1 }}>

            <CodeExplain title={'Changes from local and git'} code={`
git status
`} />

            <CodeExplain title={'DIscard local changes. If not path then . will replace all files'} code={`
git restore <path>
`} />

            <CodeExplain title={'Get changes from git'} code={`
git pulll origin <origin name>
`} />

            <CodeExplain title={'commit file changes to git repo. If no path then . will add all files which has changes'} code={`
git add <path>
git commit -m "About changes"
git push origin main 
`} />

            <CodeExplain title={'deleting folder or file from repo not from local'} code={`
git rm -r --cached myFolder
git add .
git commit -m "message"
git push origin <origin name>
`} />

        </Box>
    )
}

export default GithubScreen;