import React from 'react';

import {  Grid } from '@mui/material';

const NotFound = () => {
  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: '100vh' }}
  >
    <Grid item xs={3}>
     Not found
    </Grid>
  </Grid>
  );
};

export default NotFound;
