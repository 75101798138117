import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer.js";
import OfflineReducer from "./OfflineReducer.js";
import AlertReducer from "./AlertReducer.js";

const AppReducer = combineReducers({
    auth: AuthReducer,
    offline: OfflineReducer,
    alert: AlertReducer
})
export default AppReducer;