import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";
export const ReactNativeScreen = () => {
  return (
    <Box sx={{ flex: 1 }}>

      <CodeExplain title={'Sharing files'} code={`
export const shareIt = async (caption, message, imageUrl) => {
    try {
      let shareImage = {
        title: caption,
        message: message,
        url: imageUrl,
      };
      const result = await Share.share(shareImage);
    } catch (error) {
      console.log(error);
    }
}
`} />

      <CodeExplain title={`Connectivity check`} code={`
export const getInternetConnectionStatus = async () => {
    try {
      const networkState = await NetInfo.fetch()
        return networkState.isConnected;
    } catch (error) {
      console.log(error);
    }
    return false;
}`
      } />

      <CodeExplain title={`Key board overlapping on input`} code={`
Wrap Textinput
<ScrollView keyboardShouldPersistTaps='always'>;
    <TextInput />;
</ScrollView>;
`} />

      <CodeExplain title={`failing to create debug apk`} code={`
Bundle your js:

if you have <b>index.android.js</b> in project root then run (npx is optional)
npx react-native bundle --dev false --platform android --entry-file index.android.js --bundle-output ./android/app/build/intermediates/assets/debug/index.android.bundle --assets-dest ./android/app/build/intermediates/res/merged/debug

if you have <b>index.js</b> in project root then run
npc react-native bundle --platform android --dev false --entry-file index.js --bundle-output android/app/src/main/assets/index.android.bundle --assets-dest android/app/src/main/res
Create debug apk:

cd android/
./gradlew assembleDebug

Then You can find your apk here:
cd app/build/outputs/apk/
`} />

      <CodeExplain title={`opening android log in terminal`} code={`
adb logcat *:S ReactNative:V ReactNativeJS:V
Or 
 adb logcat '*:W'.
 From
 Link: https://medium.com/geekculture/react-native-generate-apk-debug-and-release-apk-4e9981a2ea51

 or else

Connect the device and use:
adb shell

Use logcat after the shell is set up:
logcat -v color | grep com.yourapp.packagename
`} />

      <CodeExplain title={`How to rename package in react native?`} explain={`

1. it's helpful to do a project wide search () for com.appName and replace them all with com.companyName.appName. And if you do it with VScode, remember to disable "Use Exclude Settings and Ignore Files".

2. I've renamed the project' subfolder from: "android/app/src/main/java/MY/APP/OLD_ID/" to: "android/app/src/main/java/MY/APP/NEW_ID/"

3. Then manually switched the old and new package ids:

The 2 files MainAcitivity.java and MainApplication.java should be relocated to the new physical path.

4. In: android/app/src/main/java/MY/APP/NEW_ID/MainActivity.java:
    package MY.APP.NEW_ID;

5. In android/app/src/main/java/MY/APP/NEW_ID/MainApplication.java:
    package MY.APP.NEW_ID;

6. In android/app/src/main/AndroidManifest.xml:
    package="MY.APP.NEW_ID"

7. Change applicationId in app/build.gradle too (android/app/build.gradle):
    applicationId "MY.APP.NEW_ID"

8. android/settings.gradle file? (specifically rootProject.name = 'OLD_ID').

9. Edit file BUCK to replace old package value with the new one (android/app/BUCK):
    android_build_config(
        package="MY.APP.NEW_ID"
    )
    android_resource(
        package="MY.APP.NEW_ID"
    )
    
10. Gradle' cleaning in the end (in /android folder):
    ./gradlew clean

11. android/app/src/main/res/values/strings.xml and app_name inside that file.

12. I also had to rename the import file of BasePackageList in MainApplication and in android/app/src/main/generated/BasePackageList.java 

13. For RN 0.60+, there's another LoC that has to be changed in MainApplication#initializeFlipper: Class<?> aClass = Class.forName("MY.APP.NEW_ID");   

14. if you are using firebase then you also need to replace old package value in two instances of package_name in google-services.json  and change app.json file too 

`} />

      <CodeExplain title={`Run debug app without usb.`} explain={`
Step 1: Connect both devices to the same network
Step 2: Enter the address and port number in the device developer options ( address looking like 000.000.00.000:00000)
Step 3: adb devices
Step 4: adb tcpip 5555
Step 5: adb connect <check ip in wifi>:5555 (000.000.00.000:00000)
Step 6: adb devices
Step 7: npm run android

run on specific device
npx react-native run-android --deviceId=<device Id from adb devices>
`} />

    </Box>

  )
}

export default ReactNativeScreen;