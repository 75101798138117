import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";

export const JavascriptScreen = () => {
    return (
        <Box sx={{ flex: 1 }}>
            <CodeExplain title={'Validate phone number'} code={`
export const validatePhone = (phone) => {
    return /^d+$/.test(phone) && phone.length >= 10 && phone.length < 16
}
`} />

            <CodeExplain title={`validate email address`} code={`
export const validateEmail = (text) => {
    let reg = /^w+([.-]?w+)*@w+([.-]?w+)*(.ww+)+$/;
    if (reg.test(text) === false) {
  
        return false;
    }
    return true;
}
            `} />

            <CodeExplain title={'Hourse ago'} code={`
export const showCreatedTime = (date) => {
    const d = new Date(date);
    let hour = d.getHours();
    let minutes = d.getMinutes();
    if (hour < 1) {
      return hour + ' Hours ago';
    } else {
      return minutes + ' minute ago';
    }
}     
`} />

            <CodeExplain title={'Month name from date'} code={`
export const getMonthName = (date) => {
    let month = new Date(date);
    const monthNames = ["January", "Febreuary", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[month.getMonth()];
}
`} />

        </Box>
    )
}

export default JavascriptScreen;