import { Alert, Box, Card, Container, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { CopyAll } from "@mui/icons-material";
import Slide from '@mui/material/Slide';

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}
export const CodeExplain = ({ title, code, explain }) => {

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [transition, setTransition] = useState(() => TransitionDown);

    const handleClick = (Transition) => {
        setTransition(() => Transition);
        setShowSnackbar(true);
        setTimeout(() => handleClose(), 3000);
    };

    const handleClose = () => {
        setShowSnackbar(false);
    };

    return (
        <Container component={Box} sx={{ width: '90%', margin: 3 }}>

            <Card sx={{ display: 'inline-block', padding: 5, width: '100%' }} >
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={showSnackbar}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    key={transition ? transition.name : ''}>

                    <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Copied to clip board.
                    </Alert>

                </Snackbar>
                <Typography ><span style={{ fontWeight: 'bold', fontSize: 16, padding: 5 }}>{title}</span></Typography>
                {code && <pre style={{
                    color: 'black',
                    padding: 5,
                    margin: 5,
                    width: '100%',
                    overflow: 'auto',
                    fontSize: 14
                }}>
                    <IconButton sx={{ color: "brown" }}
                        onClick={() => {
                            navigator.clipboard.writeText(code);
                            handleClick(TransitionDown);
                            console.log('clicked');
                        }}>
                        <CopyAll />
                    </IconButton>
                    {code}
                </pre>
                }
                {explain &&
                    <pre  style={{
                        color: 'black',
                        padding: 5,
                        margin: 5,
                        width: '100%',
                        overflow: 'auto',
                        fontSize: 12}}>
                        {explain}
                    </pre>}
            </Card>
        </Container>
    )
}
export default CodeExplain;

