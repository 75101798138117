const REDUX_CONSTANTS = {

    USER_CREDENTIALS: "USER_CREDENTIALS",
    USER: "USER",
    SHOW_ALERT: "SHOW_ALERT",
    THEME_MODE: "THEME_MODE",
    DRAWER_STATE: "DRAWER_STATE",

    SERVICE_LOGIN_LOADING: "SEERVICE_LOGIN_LOADING",
    SERVICE_LOGIN_SUCCESS: "SEERVICE_LOGIN_SUCCESS",
    SERVICE_LOGIN_FAILED: "SEERVICE_LOGIN_FAILED",

    SERVICE_SIGNUP_LOADING: "SEERVICE_SIGNUP_LOADING",
    SERVICE_SIGNUP_SUCCESS: "SEERVICE_SIGNUP_SUCCESS",
    SERVICE_SIGNUP_FAILED: "SEERVICE_SIGNUP_FAILED",

    SERVICE_ADMIN_DASHBOARD_LOADING: "SEERVICE_ADMIN_DASH_LOADING",
    SERVICE_ADMIN_DASHBOARD_SUCCESS: "SEERVICE_ADMIN_DASH_SUCCESS",
    SERVICE_ADMIN_DASHBOARD_FAILED: "SEERVICE_ADMIN_DASH_FAILED",

}
export default REDUX_CONSTANTS;