import thunk from 'redux-thunk';
import AppReducer from '../reducers/AppReducer';
import localStorage from 'redux-persist/es/storage';
import { getStoredState, persistReducer, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';

// Middleware: Redux Persist Config
const persistConfig = {
    // Root
    key: 'root',
    // Storage Method (React Native)
    // storage: AsyncStorage,
    storage: localStorage,

    // Whitelist (Save Specific Reducers)
    whitelist: [
        'offline',
    ],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, AppReducer);

// let store = createStore(persistedReducer,
//   {},
//   applyMiddleware(thunk)
//   );

let store = configureStore({
    reducer: persistedReducer,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware({
    //     serializableCheck: false,
    //   }),
    middleware: [thunk],
    preloadedState: {},
})

let persistor = persistStore(store);

const getStoredStore = async () => {
    let storedState = await getStoredState(persistConfig)
    return storedState;
}

export {
    store,
    persistor,
    getStoredStore
};