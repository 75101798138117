import React from 'react';
import { createTheme } from '@mui/material';
import LightTheme from './LightTheme';
import DarkTheme from './DarkTheme';
import { useSelector } from 'react-redux';



const AppTheme = () => {

  const { themeMode } = useSelector(state => state.offline);
  const looksLike = themeMode === "dark" ? DarkTheme : LightTheme;
  const theme = React.useMemo(() => createTheme(looksLike), [looksLike]);
  return theme;
  
}

export default AppTheme;