import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";
export const JavaScreen = () => {
    return (
        <Box sx={{ flex: 1 }}>

            <CodeExplain title={'Learn android develpment.'} explain={`
1. Download and install android studio from official website. 
https://developer.android.com/studio?gclid=Cj0KCQiA5rGuBhCnARIsAN11vgTtdTvf-wD5oJUJnyzYnnzsInjRQe30F7NYzwPIH3oVmASPfPkEHSIaAiIJEALw_wcB&gclsrc=aw.ds
2. Must have knwoledge in Java or kotin and xml.
3. Start with creating first project in android studio. Click on "Create New Project" when you see the "Welcome to Android Studio" screen. 
(Go to File > New > New Project if you have already had a project opened).
Select "Empty Activity", click "Next" on the "Select a Project Template" screen. Go ahead and complete project creation as per your requirements.
4. Run your app on a real device or an emulator.
`} />

        </Box>
    )
}

export default JavaScreen;