import { Box } from "@mui/material";
import React from "react";
import CodeExplain from "../common/CodeExplain";
export const ReactScreen = () => {
    return (
        <Box>
            <CodeExplain title={'Create react app'} code={'npx create-react-app my-app'} explain={'Above code will create a react application with my-app'} />
            <CodeExplain title={'Add mui'} code={'npm install @mui/material @emotion/react @emotion/styled @fontsource/roboto  @mui/icons-material'} explain={'Above code will create a react application with my-app'} />
            <CodeExplain title={'Adding react icons.'} code={'npm i react-icons'} explain={'Build size is depend on icon imported into project.'} />
        </Box>
    )
}

export default ReactScreen;