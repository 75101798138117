import { Home } from "@mui/icons-material";
import { logout } from "./AppHelper";

export const DRAWER_WIDTH = 240;
export const APP_BAR_NAME= "Taj soft"
export const USER_ROLE = {
    CUSTOMER: 1,
    MANAGER: 2,
    ADMIN: 3
}


export const USER_ROUTES = [
    {
        id:1,
        name: 'Home',
        path: '/',
        icon: Home
    },
    {
        id:2,
        name: 'Hotels',
        path: '/hotels',
        icon: Home
    },
    {
        id:3,
        name: 'Bookings',
        path: '/bookings',
        icon: Home
    },
    {
        id:4,
        name: 'Logout',
        onclick: logout,
        icon: Home
    },
]

// export const DrawerComponent = [
//     {
//         name: 'Dashboard',
//         onClick: () => { },
//         path: '/',
//         icon: Home
//     },
//     {
//         name: 'Users',
//         path: '/users',
//         onClick: () => { },
//         icon: People
//     }
// ]

// export const ProfileOptions = [
//     {
//         name: 'Change password',
//         icon: Lock,
//         onClick: () => { }
//     },
//     {
//         name: 'logout',
//         icon: Logout,
//         onClick: () => { logout() }
//     }
// ]