import { combineReducers } from "redux";
import REDUX_CONSTANTS from "../utils/ReduxConstants";

const isLoginLoading = (state = false, action) => {
  switch (action.type) {
    case REDUX_CONSTANTS.SERVICE_LOGIN_LOADING:
      return true;
    case REDUX_CONSTANTS.SERVICE_LOGIN_FAILED:
    case REDUX_CONSTANTS.SERVICE_LOGIN_SUCCESS:
      return false
    default:
      return state;
  }
}

const isSignupLoading = (state = false, action) => {
  switch (action.type) {
    case REDUX_CONSTANTS.SERVICE_SIGNUP_LOADING:
      return true;
    case REDUX_CONSTANTS.SERVICE_SIGNUP_SUCCESS:
    case REDUX_CONSTANTS.SERVICE_SIGNUP_FAILED:
      return false
    default:
      return state;
  }
}

export default combineReducers({
  isLoginLoading,
  isSignupLoading
})