import { combineReducers } from "redux";
import REDUX_CONSTANTS from "../utils/ReduxConstants";

const userCredentials = (state = { token: null, role: 0 }, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.USER_CREDENTIALS:
            return action.data
        default:
            return state;
    }
};

const userDetails = (state = { token: null, role: null }, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.USER:
            return action.data
        default:
            return state;
    }
};

const themeMode = (state = 'light', action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.THEME_MODE:
            return action.data
        default:
            return state;
    }
}

const drawerState = (state = true, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.DRAWER_STATE:
            return action.data;
        default:
            return state;
    }
}

export default combineReducers({
    userDetails,
    themeMode,
    userCredentials,
    drawerState
})