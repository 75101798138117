import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Toolbar, Box, Typography, Divider, IconButton, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { DrawerComponent } from './DrawerConstants';
import { useDispatch, useSelector } from 'react-redux';
import { updateDrawerState } from '../actions/offline';
import { AppBar, Drawer, DrawerHeader } from '../theme/DrawerStyles';
import { APP_BAR_NAME } from '../utils/Constants';
import COLORS from '../utils/Colors';
import { NavLink } from 'react-router-dom';

// Sidebar component
const AdminAppBar = () => {

  const dispatch = useDispatch();
  const { drawerState } = useSelector(state => state.offline);

  // const [activeRoute, setActiveRoute] = useState();

  const handleDrawerOpen = () => {
    dispatch(updateDrawerState(true));

  };
  const handleDrawerClose = () => {
    dispatch(updateDrawerState(false));

  };

  // const activetRoute = route => {
  //   setActiveRoute(route);
  //   const path = window.location.pathname
  //   return route === path;
  // }


  return (
    <Box >
      <AppBar position="fixed" open={drawerState}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(drawerState && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {APP_BAR_NAME}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={drawerState}>
        <DrawerHeader sx={{ backgroundColor: COLORS.primary }}>
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
            <img src='https://tajsoft.in/web_assets/tajsoft/taj.png' alt='' loading='lazy' style={{ maxHeight: '60px' }} />
          </Box>
          <IconButton onClick={handleDrawerClose} sx={{ color: COLORS.white }}>
            <MenuIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <nav>

          <List>

            {DrawerComponent.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  component={NavLink}
                  to={item.path}
                  button="true"
                  // selected={(activetRoute(item.path))}
                  // onClick={activetRoute(item.path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawerState ? 'initial' : 'center',
                    px: 2.5,
                  }}>

                  {
                    item.icon ? <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerState ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <item.icon />
                    </ListItemIcon> : null
                  }

                  {item.name ? <ListItemText primary={item.name} sx={{ opacity: drawerState ? 1 : 0 }} /> : null}

                </ListItemButton>
                <Divider />
              </ListItem>
            ))}

          </List>
        </nav>
      </Drawer>
    </Box>
  );
};

export default AdminAppBar