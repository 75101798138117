const COLORS = {
    primary: "#800080",
    secondary:"#333333",
    transparent: 'rgba(0, 0, 0, 0.1)',


    themeBlue: '#1AACE5',
    white: '#fff',
    whiteTransparent: '#ffffff66',
    textHeaderBlack: '#262626',
    textLightBlack: '#646464',
    textLightGrey: '#AAAAAA',
    textBlue: '#1AACE5',
    iconGrey: '#AAAAAA',
    lightBlueBg: '#ADD8E6',
    borderGrey: '#CFD9DE',
    textBlack: '#262626',
    lightGrey: '#EFEFEF',
    black: "#333333",
    orange: "#FFA20D",
    textGrey: '#536471',
    bgGrey: "#E9EEF0",
    red: '#fe281e',
    green: "#62DB44",
    lightGreen: "#6F96A9",
    grey: '#818181',
    newGrey: '#A9A9A9',
    bgTransparent: "transparent",
    white90Op: 'rgba(255,255,255,0.9)',
    white70Op: 'rgba(255,255,255,0.7)',
    white80Op: 'rgba(255,255,255,0.8)',
    white50Op: 'rgba(255,255,255,0.5)',
    white40Op: 'rgba(255,255,255,0.4)',
    white30Op: 'rgba(255,255,255,0.3)',
    white20Op: 'rgba(255,255,255,0.2)',
    white10Op: 'rgba(255,255,255,0.1)',
    grey1: "#A3A3A3",
    darkGrey: "#707070",
    darkPink: '#FF0055',
    purpleGradient: '#03D8DE',
    blueGradient: '#0ACDFE',
    darkBlue: '#294178'
};
export default COLORS;
