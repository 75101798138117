import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material'
import RootNavigation from './navigation/RootNavigation';
import AppTheme from './theme/AppTheme';
function App() {

  const theme = AppTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootNavigation />
    </ThemeProvider>
  );
}

export default App;
