import REDUX_CONSTANTS from "../utils/ReduxConstants";

export function updatThemeMode(mode = 'light') {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.THEME_MODE, data: mode })
    }
}

export function updateCrdentials(credentials) {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.USER_CREDENTIALS, data: credentials })
    }
}

export function updateDrawerState(drawerState = true) {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.DRAWER_STATE, data: drawerState })
    }
}

export function updateUser(user) {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.USER, data: user });
    }
}