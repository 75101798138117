import { CheckCircle, Facebook, Instagram, Twitter } from "@mui/icons-material";
import {
    Typography,
    Container,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Card,
    CardContent,
    CardMedia,
    Link,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";

const About = () => {
    const appLogoUrl = 'https://tajsoft.in/web_assets/tajsoft/taj.png'; // Replace with your app logo URL
    const teamImageUrl = 'https://www.liquidplanner.com/wp-content/uploads/2019/04/HiRes-44-e1478037191772.jpg'; // Replace with your team image URL

    const socialMediaLinks = {
        facebook: 'https://www.facebook.co',
        twitter: 'https://twitter.com',
        instagram: 'https://www.instagram.com',
    };
    const companyWebsite = 'https://tajsoft.in/';
    return (
        <div
            style={{
                flexGrow: 1,
                padding: '16px',
                background: 'linear-gradient(135deg, #6C63FF, #00F0FF)', // Shiny gradient background
                minHeight: '100vh',
                color: '#FFFFFF', // Font color for the screen
                minWidth: '100%',
            }}
        >

            <Container maxWidth="md">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CardMedia
                        component="img"
                        image={appLogoUrl}
                        onLoad={() => console.log("this is loading")}
                        onError={() => console.log("this is error")}
                        alt="This is a sample image"
                        sx={{
                            maxWidth: {
                                xs: "100%",
                                sm: "500px",
                            },
                            objectFit: "cover",
                        }}
                    />
                    <Typography component="div" variant="h5" textAlign="left" sx={{ flexGrow: 1, width: '100%' }} gutterBottom>
                        Coding hints for developers
                    </Typography>
                    <Typography variant="body1" align="center">
                        Code hints help you to create your code. This blog shows the expereinced code to implement in your project and increase productivity.
                        To insert a code hint, just copy and paste into your code.
                    </Typography>
                </Box>
                <Divider />
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Features and Benefits:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle sx={{ color: green[500] }} />
                            </ListItemIcon>
                            <ListItemText primary="Easy available" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle sx={{ color: green[500] }} />
                            </ListItemIcon>
                            <ListItemText primary="Most commonly used codes" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle sx={{ color: green[500] }} />

                            </ListItemIcon>
                            <ListItemText primary="Real-time updates" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle sx={{ color: green[500] }} />

                            </ListItemIcon>
                            <ListItemText primary="Code from most experienced persons" />
                        </ListItem>
                        {/* Add more feature list items as needed */}
                    </List>
                </Box>
                <Divider />
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Contact Information:
                    </Typography>
                    <Typography variant="body1">
                        For support and inquiries, contact us at:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Email: tajsoft@gmail.com
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '8px',
                            marginBottom: '16px',
                        }}
                    >
                        <Link href={socialMediaLinks.facebook} sx={{ color: '#1877F2' }} >
                            <Facebook />

                        </Link>

                        <Link href={socialMediaLinks.twitter}>
                            <Twitter sx={{ color: '#1DA1F2' }} />
                        </Link>
                        <Box sx={{
                            backgroundColor: 'linear-gradient(135deg, #6C63FF, #00F0FF)',
                            color: 'linear-gradient(135deg, #6C63FF, #00F0FF)',

                        }}>
                            <Link href={socialMediaLinks.instagram} sx={{
                                color: 'linear-gradient(135deg, #6C63FF, #00F0FF)'
                            }}>
                                <Instagram sx={{
                                    color: '#E4405F', backgroundColor: 'linear-gradient(135deg, #6C63FF, #00F0FF)'
                                }} />
                            </Link>
                        </Box>

                    </Box>
                    <Typography variant="body2" gutterBottom>
                        Visit our website:{' '} <a style={{ color: '#FFFFFF', textDecoration: 'none' }} href={companyWebsite}>{companyWebsite}</a>
                    </Typography>
                </Box>
                <Divider />
                <Typography
                    variant="body2"
                    style={{ marginTop: '24px', color: 'rgba(255, 255, 255, 0.7)' }}
                >
                    Hotel Management App is developed by Indea Design Systems Pvt. Ltd.
                </Typography>
                {/* Team Card */}
                <Card variant="outlined" elevation={0} style={{ marginTop: '24px' }}>
                    <CardMedia
                        component="img"
                        image={teamImageUrl}
                        alt="Team"
                        style={{ height: '200px' }}
                    />
                    <CardContent>
                        <Typography variant="h6">Our Team</Typography>
                        <Typography variant="body2">
                            We are a dedicated team of professionals committed to providing
                            the best solutions for everyone. Our expertise ensures
                            the success of your career.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>

    );
}
export default About;