import {  Logout, Lock } from "@mui/icons-material"
import { logout } from "../utils/AppHelper"
import { AndroidIcon, CrownIcon, GithubIcon, JavaScriptIcon, NodeIcon, PhpIcon, ReactIcon, ReactNativeIcon } from "../utils/icon"

export const DrawerComponent = [
    {
        name: 'Java',
        path: '/java',
        onClick: () => { },
        icon: AndroidIcon
    },
    {
        name: 'Javascript',
        path: '/javascript',
        onClick: () => { },
        icon: JavaScriptIcon
    },
    {
        name: 'React',
        onClick: () => { },
        path: '/react',
        icon: ReactIcon
    },
    {
        name: 'React Native',
        path: '/reactnative',
        onClick: () => { },
        icon: ReactNativeIcon
    },
    {
        name: 'PHP',
        path: '/php',
        onClick: () => { },
        icon: PhpIcon
    },
    {
        name: 'Node',
        path: '/nodejs',
        onClick: () => { },
        icon: NodeIcon
    },
    {
        name: 'Github',
        path: '/github',
        onClick: () => { },
        icon: GithubIcon
    },
    {
        name: 'About Us',
        path: '/about',
        onClick: () => { },
        icon: CrownIcon
    }
]

export const ProfileOptions = [
    {
        name: 'Change password',
        icon: Lock,
        onClick: () => { }
    },
    {
        name: 'logout',
        icon: Logout,
        onClick: () => { logout() }
    }
]