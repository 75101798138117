import COLORS from "../utils/Colors";

const LightTheme = {
    palette: {
        mode: "light",
        primary: {
            main: COLORS.primary
        },
        secondary: {
            main: COLORS.secondary
        }
    },
    typography: {
        fontFamily: 'Roboto',
    },
    shape: {
        borderRadius: 0
    },

    components: {
        // Name of the component
        Container: {
            styleOverrides: {
                root: {
                    minHeight: '100%',
                    minWidth: '100%',

                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                    // border: 0,
                    borderRadius: 30,
                    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                    // color: 'white',
                    // height: 48,
                    // padding: '0 30px',
                    textTransform: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    // pl: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    verticalAlign: 'middle',
                    borderRadius: '20px',
                    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                    // border: 0,
                    // borderRadius: 3,
                    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                    // color: 'white',
                    // height: 48,
                    // padding: '20px 10px'
                }
            },
        },
    },
};

export default LightTheme;