import React from "react"
import { Box } from "@mui/material"
import { Route, BrowserRouter, Routes } from "react-router-dom"
import { DrawerHeader } from "../theme/DrawerStyles"
import PhpScreen from "../component/php"
import ReactScreen from "../component/react"
import JavaScreen from "../component/java"
import ReactNativeScreen from "../component/reactnative"
import About from "../component/about"
import AdminAppBar from "./AdminAppBar"
import NotFound from "../component/common/NotFound"
import NodejsScreen from "../component/node"
import JavascriptScreen from "../component/javascript"
import COLORS from "../utils/Colors"
import { GithubScreen } from "../component/github"


const RootNavigation = () => {
    return (
        <Box sx={{ display: 'flex', backgroundColor: COLORS.bgGrey, flex: 1, height: '100%' }} style={{ width: '100%' }}>
            <BrowserRouter>
                <AdminAppBar />
                <Box component="main" sx={{ flexGrow: 1, p: 0, m: 0, backgroundColor: COLORS.bgGrey, flex: 1, height: '100%' }} style={{ width: '100%' }}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/" element={<About />} />
                        <Route path="/php" element={<PhpScreen />} />
                        <Route path="/react" element={<ReactScreen />} />
                        <Route path="/java" element={<JavaScreen />} />
                        <Route path="/reactnative" element={<ReactNativeScreen />} />
                        <Route path="/nodejs" exact element={<NodejsScreen />} />
                        <Route path="/javascript" element={<JavascriptScreen />} />
                        <Route path="/github" element={<GithubScreen />} />
                        <Route path="/about" exact element={<About />} />
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </Box>
            </BrowserRouter>
        </Box>
    )
}

export default RootNavigation;